import React from "react";
import { Icon } from "@iconify/react";
import image1 from "../assets/image/icon_maze.svg";
import image2 from "../assets/image/icon miro.svg";

export default function Skill() {
  var skill = [
    {
      nama: "Figma",
      point: "Figjam, Figma Design, Auto layout, Wireframe, Prototype",
      logo: "devicon:figma",
      image: null,
    },
    {
      nama: "Maze",
      point: "Usability Testing, Usability Report Document",
      logo: "devicon:php",
      image: image1,
    },
    {
      nama: "Miro",
      point: "Brainstorming, User Persona, User Journey Map, Moodboard",
      logo: "devicon:php",
      image: image2,
    },
    {
      nama: "Adobe XD",
      point: "Mock up Design, Wireframe, Prototype",
      logo: "logos:adobe-xd",
      image: null,
    },
    {
      nama: "Trello",
      point: "Task Management, Colaboration Team, Monitoring",
      logo: "logos:trello",
      image: null,
    },
    {
      nama: "Photoshop",
      point: "Image Optimation, Slicing dan Export",
      logo: "devicon:photoshop",
      image: null,
    },
    {
      nama: "C++",
      point: "Algorithm Programming, Object Oriented Programming",
      logo: "logos:c-plusplus",
      image: null,
    },
    {
      nama: "XAMPP",
      point: "MySQL, PHPMyAdmin",
      logo: "logos:xampp",
      image: null,
    },
    {
      nama: "HTML",
      point: "Base Stucture, Text Element, Hyperlink,Form, Atribut",
      logo: "skill-icons:html",
      image: null,
    },
    {
      nama: "CSS",
      point: "Styling Text, Layouting, Coloring, Transformation",
      logo: "skill-icons:css",
      image: null,
    },
    {
      nama: "PHP",
      point: "Dynamic Page, File Management, OOP, Session",
      logo: "logos:php",
      image: null,
    },
    {
      nama: "Javascript",
      point: "DOM Manipulation, Event, AJAX, Websocket",
      logo: "skill-icons:javascript",
      image: null,
    },
    {
      nama: "Dart",
      point: "Mobile Programming, OOP, Flutter Framework",
      logo: "logos:dart",
      image: null,
    },
    {
      nama: "Github",
      point:
        "Repository, Brancing & Merging, Pull Requess, Collaborators & Team",
      logo: "skill-icons:github-dark",
      image: null,
    },
  ];
  return (
    <>
      <h3 className="text-center text-4xl text-white">Skill</h3>
      <p className="text-center text-md text-white">
        Pengalaman dan Belajar Mandiri
      </p>
      <div className="flex flex-wrap md:gap-10 gap-2 justify-center mt-10">
        {skill.map((data, index) => (
          <div
            key={index}
            className="flex md:w-[250px] w-[100px] items-center px-4 gap-2 py-2 bg-[#9855D0] bg-opacity-40 rounded-3xl"
          >
            <div className="flex md:flex-nowrap flex-wrap md:justify-normal justify-center items-center gap-3">
              {data.image == null ? (
                <div className="md:w-[50px] w-[25px]">
                  <Icon icon={data.logo} width={"100%"} />
                </div>
              ) : (
                <div className="md:w-[50px] w-[25px]">
                  <img src={data.image} alt="" className="w-100" />
                </div>
              )}

              <div className="">
                <p className="md:text-2xl text-[10px] text-white md:text-start text-center">
                  {data.nama}
                </p>
                <p className="md:text-[9px] text-[7px] text-[#9855D0] md:text-start text-center">
                  {data.point}
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </>
  );
}
