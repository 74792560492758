import React, { useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import image1 from "../assets/image/1.png";
import image2 from "../assets/image/2.png";
import image3 from "../assets/image/3.png";
import image4 from "../assets/image/4.png";
import image5 from "../assets/image/5.png";

import { Link } from "react-router-dom";

export default function Project() {
  const data = [
    {
      id: 1,
      title: "Eduspace",
      level: "MSIB",
      description: "Aplikasi Edutech penggabungan learning & discusion",
      image: image1,
      tahun: 2023,
      link: "https://www.figma.com/proto/xS2hiSAgimrVXgS4ff1seD/EduSpace_UI-(Copy)?page-id=1%3A5&type=design&node-id=563-13454&viewport=639%2C139%2C0.02&t=8wpoqw8fGIc1u5tx-1&scaling=scale-down&starting-point-node-id=563%3A13454&show-proto-sidebar=1&mode=design",
    },
    {
      id: 2,
      title: "CV. MRAmanah",
      level: "lain",
      description: "Website perusahaan percetakan buku dan jurnal",
      image: image5,
      tahun: 2023,
      link: "https://www.figma.com/proto/kdJjlJqII7mYsx2dhhIdTT/UI---MR-Amanah-Vol-1-(Copy)?page-id=16%3A4&type=design&node-id=212-2&viewport=594%2C497%2C0.1&t=1vsYlQfJpYTwaXA5-1&scaling=min-zoom&starting-point-node-id=212%3A2&mode=design",
    },
    {
      id: 3,
      title: "Maxim",
      level: "MSIB",
      description:
        "Redesign Maxim apps dengan menerapkan Metode Design Thinking",
      image: image2,
      tahun: 2023,
      link: "https://www.figma.com/proto/ISW7udHWlR4xuC8CxiDlfd/Wireframes%2C-UI-Design%2C-Prototype-(Copy)?page-id=166%3A3508&type=design&node-id=5-13463&viewport=18%2C363%2C0.08&t=fVvMw9tYlwUfegVd-1&scaling=min-zoom&starting-point-node-id=5%3A14858&mode=design",
    },
    {
      id: 4,
      title: "GoPick",
      level: "MSIB",
      description: "Aplikasi penitipan barang modern yang terpercaya",
      image: image3,
      tahun: 2023,
      link: "https://www.figma.com/proto/dWgr77FkhFD4SJwFQULUsF/Chapter-4---Rafi%2C-Risma%2C%C2%A0Lutfiyyah-(Copy)?page-id=1%3A6&type=design&node-id=298-1878&viewport=610%2C61%2C0.02&t=cMmtMsnwgg7c5pWQ-1&scaling=scale-down&starting-point-node-id=298%3A1878&show-proto-sidebar=1&mode=design",
    },
    {
      id: 5,
      title: "E-Commerce",
      level: "Kuliah",
      description:
        "Aplikasi belanja online yang menyediakan berbagai furniture estetik",
      image: image4,
      tahun: 2023,
      link: "https://www.figma.com/proto/DQmBXvYf4ed9qVOagnCI0d/Wireframe-(Community)-(Copy)?page-id=120%3A5&type=design&node-id=322-1971&viewport=349%2C-516%2C0.05&t=gioP1DTnVgHvA6xe-1&scaling=scale-down&starting-point-node-id=322%3A1971&show-proto-sidebar=1&mode=design",
    },
  ];

  const [activeTab, setActiveTab] = useState("all");
  const [filteredData, setFilteredData] = useState(data);

  const handleTabClick = (level) => {
    setActiveTab(level);
    if (level === "all") {
      setFilteredData(data);
    } else {
      const filtered = data.filter((item) => item.level === level);
      setFilteredData(filtered);
    }
  };

  return (
    <>
      <h3 className="text-center text-white text-4xl mb-3"> My Project</h3>
      <div className="flex justify-center">
        <button
          onClick={() => handleTabClick("all")}
          className={`md:px-9 md:py-2 px-4 py-1 text-sm md:text-xl font-semibold border-b-4 rounded-t-xl ${
            activeTab === "all"
              ? "bg-[#9855D0] text-white text-opacity-40 bg-opacity-40 border-[#9855D0]"
              : "text-white border-white border-opacity-25"
          }`}
        >
          All
        </button>
        <button
          onClick={() => handleTabClick("MSIB")}
          className={`md:px-9 md:py-2 px-4 py-1 text-sm md:text-xl font-semibold border-b-4 rounded-t-xl ${
            activeTab === "MSIB"
              ? "bg-[#9855D0] text-white text-opacity-40 bg-opacity-40 border-[#9855D0]"
              : "text-white border-white border-opacity-25"
          }`}
        >
          MSIB
        </button>
        <button
          onClick={() => handleTabClick("Kuliah")}
          className={`md:px-9 md:py-2 px-4 py-1 text-sm md:text-xl font-semibold border-b-4 rounded-t-xl ${
            activeTab === "Kuliah"
              ? "bg-[#9855D0] text-white text-opacity-40 bg-opacity-40 border-[#9855D0]"
              : "text-white border-white border-opacity-25"
          }`}
        >
          Kuliah
        </button>
        <button
          onClick={() => handleTabClick("lain")}
          className={`md:px-9 md:py-2 px-4 py-1 text-sm md:text-xl font-semibold border-b-4 rounded-t-xl ${
            activeTab === "lain"
              ? "bg-[#9855D0] text-white text-opacity-40 bg-opacity-40 border-[#9855D0]"
              : "text-white border-white border-opacity-25"
          }`}
        >
          Lain-lain
        </button>
      </div>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={4000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect={false}
        infinite={false}
        itemClass=""
        keyBoardControl={true}
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={{
          desktop: {
            breakpoint: {
              max: 3000,
              min: 1024,
            },
            items: 4,
            partialVisibilityGutter: 40,
          },
          mobile: {
            breakpoint: {
              max: 464,
              min: 0,
            },
            items: 2,
            partialVisibilityGutter: 10,
          },
          tablet: {
            breakpoint: {
              max: 1024,
              min: 464,
            },
            items: 2,
            partialVisibilityGutter: 30,
          },
        }}
        rewind={true}
        rewindWithAnimation={true}
        rtl={false}
        shouldResetAutoplay
        removeArrowOnDeviceType={["tablet", "mobile"]}
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable={true}
      >
        {filteredData.map((item, index) => (
          <div
            key={index}
            className="bg-[#D4ADFC] bg-opacity-25 md:h-[90%] text-white m-2 mt-10 rounded-2xl"
          >
            <img src={item.image} alt="contoh" className="w-full rounded-2xl" />
            <div className="p-3">
              <div className="">
                <p className="sm:absolute text-center text-[8px] md:text-[12px] right-6 text-[#D4ADFC] border p-1 rounded-lg border-[#D4ADFC]">
                  {item.tahun}
                </p>
                <h3 className="sm:w-9/12 text-center sm:mt-0 mt-[10px] md:text-lg text-[13px]">
                  {item.title}
                </h3>
              </div>
              <p className="md:text-md md:h-[35px] text-[11px] sm:block hidden">
                {item.description}
              </p>
            </div>
            <div className="grid place-items-center p-3">
              {item.link == null ? (
                ""
              ) : (
                <Link
                  to={item.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="bg-gradient-to-br from-[#9D3AF0] to-[#702FA7] py-1 px-3 rounded-lg text-[8px] mt-3 shadow-md"
                >
                  View Project
                </Link>
              )}
            </div>
          </div>
        ))}
      </Carousel>
    </>
  );
}
