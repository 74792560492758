import React, { useState } from "react";
import { Icon } from "@iconify/react";

export default function Form() {
  const [successAlert, setSuccessAlert] = useState(false);
  const [errorAlert, setErrorAlert] = useState(false);
  const [open, setOpen] = React.useState(true);

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formDataObj = {
      name: event.target.elements.name.value,
      email: event.target.elements.email.value,
      pesan: event.target.elements.pesan.value,
    };

    // console.log(formDataObj);

    try {
      const response = await fetch("https://api.dreamit.my.id/2", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formDataObj),
      });

      if (!response.ok) {
        throw new Error("Failed to create Data");
      }

      //   const result = await response.json();
      //   setSuccessAlert(true);
      setErrorAlert(true);
      //   console.log(result);
    } catch (error) {
      //   console.log(error);
      setErrorAlert(true);
    }

    setOpen(true);
    setTimeout(() => {
      setSuccessAlert(false);
      setErrorAlert(false);
    }, 4000);
  };

  const closeAlert = () => {
    setOpen(false);
  };

  return (
    <>
      <h3 className="text-white text-center text-4xl">Contact Me</h3>
      <p className="text-center text-[#9855D0]">For mode information</p>
      <div className="bg-[#9855D0] bg-opacity-40 p-10 rounded-[30px] mt-10">
        <div className="text-white">
          <h3 className="text-md lg:text-2xl">Need information about me?</h3>
          <p className="text-[12px] lg:text-xl">
            Silahkan tinggalkan pesan melalui form dibawah ini. Dengan
            komunikasi akan menjalin kerjasama yang lebih baik. <br></br> Terima
            Kasih
          </p>
        </div>
        <div className="mt-5">
          <form onSubmit={handleSubmit}>
            <div className="flex flex-wrap md:flex-nowrap gap-3">
              <div className="md:w-1/2 w-full">
                <label
                  htmlFor="name"
                  className="bg-white mt-[1px] text-[#9855D0] border border-[#9855D0] absolute rounded-3xl py-[2px] px-5 lg:text-md text-sm lg:py-2 lg:px-10"
                >
                  Nama
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  className="bg-[#9855D0] text-white pl-20 lg:pl-32 bg-opacity-40 w-full lg:text-md text-sm lg:py-2 rounded-3xl border border-2 border-[#9855D0]"
                  required
                />
              </div>
              <div className="md:w-1/2 w-full">
                <label
                  htmlFor="email"
                  className="bg-white mt-[1px] text-[#9855D0] border border-[#9855D0] absolute rounded-3xl py-[2px] px-5 lg:text-md text-sm lg:py-2 lg:px-10"
                >
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="bg-[#9855D0] text-white pl-20 lg:pl-32 bg-opacity-40 w-full lg:text-md text-sm lg:py-2 rounded-3xl border border-2 border-[#9855D0]"
                  required
                />
              </div>
            </div>
            <div className="mt-4">
              <textarea
                name="pesan"
                id="pesan"
                className="bg-[#9855D0] text-white bg-opacity-40 w-full p-5 rounded-3xl border border-2 border-[#9855D0]"
                required
              ></textarea>
            </div>
            <div className="grid place-items-center mt-5">
              <button
                type="submit"
                className="bg-gradient-to-tl from-[#9D3AF0] to-[#0000] hover:bg-[#9D3AF0] text-white font-bold py-2 px-4 rounded-[15px] flex items-center gap-3"
              >
                Kirim Pesan
              </button>
            </div>
          </form>
        </div>
      </div>
      {successAlert && open && (
        <div
          className="absolute md:w-1/2 text-white rounded-xl lg:w-3/8 bg-green-500 bg-opacity-80 p-10 top-24 right-8"
          style={{ position: "fixed" }}
        >
          <div className="alert success">
            <button
              className="absolute right-0 p-5 top-[-10px]"
              onClick={closeAlert}
            >
              <Icon icon="ph:x-circle-bold" width={30} color="red" />
            </button>
            <div className="text-2xl">Success</div>
            Pesan akan dibalas melalui Email
          </div>
        </div>
      )}
      {errorAlert && open && (
        <div
          className="absolute md:w-1/2 text-white rounded-xl lg:w-3/8 bg-red-500 bg-opacity-80 p-10 top-24 right-8"
          style={{ position: "fixed" }}
        >
          <div className="alert error">
            <button
              className="absolute right-0 p-5 top-[-10px]"
              onClick={closeAlert}
            >
              <Icon icon="ph:x-circle-bold" width={30} color="red" />
            </button>
            <div className="text-2xl">Error</div>
            Pesan form melebihi batas harian, silahkan hubungi melalui{" "}
            <strong>Media Sosial</strong> tertera
          </div>
        </div>
      )}
    </>
  );
}
