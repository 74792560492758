import React from "react";
import Navbar from "./components/navbar";
import "./App.css";
import { Icon } from "@iconify/react";
import Project from "./components/project";
import Skill from "./components/skill";
import Form from "./components/form";
import { Link } from "react-router-dom";

import Image_didik from "./assets/image/image_neng.svg";

function App() {
  var experience = [
    {
      tempat: "UPNVYK",
      posisi: "Asisten Laboratorium",
      deskripsi:
        "Membantu pengelolaan perangkat, memberikan dukungan teknis, dan memfasilitasi praktikum untuk mahasiswa, memastikan kelancaran aktivitas laboratorium.",
    },
    {
      tempat: "CV Malik Rizki Amanah",
      posisi: "UI/UX Design",
      deskripsi:
        "Merancang antarmuka website perusahaan dengan menggunakan design thinking serta menerapkan eight golden rules",
    },
    {
      tempat: "Binar Academy (MSIB)",
      posisi: "UI/UX Research & Design",
      deskripsi:
        "Melakukan riset pengalaman pengguna (UX) dan menginterpretasikannya dalam bentuk design antarmuka sistem mobile maupun web.",
    },
    {
      tempat: "UPNVYK",
      posisi: "MC & Moderator",
      deskripsi:
        "Memandu berbagai acara kampus mulai dari acara himpunan mahasiswa hingga kuliah umum dan memastikan jalannya acara sesuai dengan rundown yang sudah disepakati.",
    },
  ];
  return (
    <div>
      <header className="fixed z-50">
        <Navbar />
      </header>
      <main className="" style={{ backgroundColor: "#0C102D" }}>
        <div className="lg:h-screen" id="about">
          <div className="absolute z-10 lg:h-full flex items-center flex-col-reverse lg:flex-row gap-10 lg:mx-20 md:mx-20 mx-7 my-3">
            <div className="lg:w-2/4">
              <h4 className="text-[#D4ADFC] md:text-3xl text-xl">
                Hallo Saya,{" "}
              </h4>
              <div className="text-white ">
                <h3 className="md:text-4xl text-2xl md:py-4 py-2">
                  Luthfiyyah Nurhasannah
                </h3>
                <p>
                  Mahasiswa aktif semester 7, memiliki minat dalam bidang UI dan
                  UX. Memiliki pengalaman lebih dari 1 Tahun dalam UI dan UX
                  Design dengan berbagai metode seperti design thinking, five
                  plans, UCD dll. Terampil dalam mengoperasikan berbagai tools
                  design seperti figma, adobe photoshop, miro dll. Saya sangat
                  antusias dalam belajar dan menerima tantangan baru dalam
                  mendesign berbagai platform dengan implementasi berbagai tools
                  dan metode.
                </p>
                <div className="mt-10 flex gap-10">
                  <Link
                    to={
                      "https://drive.google.com/u/0/uc?id=14N5f5dZr_xRNs_M2P0r_ej2ey7SylS2E&export=download"
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-gradient-to-tl from-[#9D3AF0] to-[#0000] hover:bg-[#9D3AF0] text-white font-bold py-2 px-4 rounded flex items-center gap-3"
                  >
                    <Icon icon="mingcute:file-download-line" width={25} />
                    Resume
                  </Link>
                  <Link
                    to={"https://www.linkedin.com/in/luthfiyyah-n-h"}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-white hover:bg-blue-700 text-[#9855D0] hover:bg-[#9D3AF0] hover:text-white font-bold py-2 px-4 rounded flex items-center gap-3"
                  >
                    <Icon icon="raphael:linkedin" width={25} /> Linkedin
                  </Link>
                </div>
              </div>
            </div>
            <div className="lg:w-2/4 lg:mt-0 mt-[80px] grid place-items-center">
              <img src={Image_didik} alt="Image_1" className="w-4/6" />
              <div className="w-full">
                <h4 className="text-center md:mt-7 pt-4 text-[#D4ADFC] md:text-2xl">
                  UI/UX Designer
                </h4>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center gap-10">
            <div className="shape-1 blur-[110px]"></div>
            <div className="shape-2 blur-[110px]"></div>
            <div className="shape-3 blur-[110px]"></div>
            <div className="shape-4 blur-[110px]"></div>
          </div>
          <div className="flex justify-end gap-20">
            <div className="shape-5 blur-[110px]"></div>
            <div className="shape-6 blur-[110px]"></div>
          </div>
        </div>
        <div
          className="lg:h-screen lg:mt-[-10rem] md:mt-[-2rem] mt-[-15rem]"
          id="experience"
        >
          <div className="flex h-full justify-start items-end gap-40 md:mt-[18rem] lg:mt-0 mt-[9rem] ">
            <div className="absolute z-10 lg:h-full w-full flex items-center flex-col lg:flex-row md:px-20">
              <div className="lg:w-3/12 w-full text-white text-3xl">
                <h3 className="md:text-4xl text-3xl lg:text-start  text-center lg:mb-1 mb-7">
                  Experience
                </h3>
                {/* <p className="md:text-sm text-[13px] lg:text-start text-center lg:mb-0 mb-6">
                  More than 20 months of experience
                </p> */}
              </div>
              <div className="lg:w-9/12">
                <div className="flex flex-wrap justify-center md:gap-7 gap-2">
                  {experience.map((data, index) => (
                    <div
                      key={index}
                      className="bg-[#9855D0] rounded-[30px] bg-clip-padding backdrop-filter backdrop-blur-sm bg-opacity-10 border border-[#9855D0] text-white p-5 md:w-3/8 w-5/12"
                    >
                      <Icon
                        icon="mdi:monitor-multiple"
                        width={30}
                        color="#9855D0"
                      />
                      <h4 className="md:text-2xl text-[10px]">
                        {data.posisi} -{" "}
                        <span className="text-[#D4ADFC]">{data.tempat}</span>
                      </h4>

                      <p className="md:text-[16px] text-[8px]">
                        {data.deskripsi}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="shape-5 ml-[-6rem] mb-[-16rem] blur-[110px]"></div>
            <div className="shape-6 mb-[-16rem] blur-[110px]"></div>
            <div className="shape-7 mb-[-16rem] blur-[110px]"></div>
          </div>
        </div>
        <div
          className="lg:h-screen mt-[13rem] md:mt-[20rem] lg:mt-[0rem]"
          id="project"
        >
          <div className="flex h-full justify-end items-end gap-40">
            <div className="absolute z-10 lg:h-full w-full md:mt-14 mt-10 md:px-20 px-10">
              <Project />
            </div>
            <div className="shape-6 ml-[-10rem] mb-[-16rem] blur-[110px]"></div>
            <div className="shape-7 mb-[-16rem] blur-[110px]"></div>
            <div className="shape-8 mb-[-16rem] blur-[110px]"></div>
          </div>
        </div>
        <div
          className="lg:h-screen lg:mb-[-26rem] mb-[10rem] md:mt-[45rem] mt-[43rem] lg:mt-[-5rem]"
          id="skills"
        >
          <div className="flex h-full justify-end items-end gap-40">
            <div className="absolute z-10 lg:h-full w-full md:mt-14 mt-10 md:px-20 px-10">
              <Skill />
            </div>
            <div className="shape-8 mb-[-16rem] blur-[110px]"></div>
            <div className="shape-6 ml-[-10rem] mb-[-16rem] blur-[110px]"></div>
            <div className="shape-7 mb-[-16rem] blur-[110px]"></div>
          </div>
        </div>
        <div
          className="lg:h-screen md:mt-[7rem] lg:mt-[15rem] mt-[-4rem]"
          id="contact"
        >
          <div className="absolute z-10 w-full md:mt-14 lg:mt-10 md:px-20 px-10">
            <Form />
          </div>
          <div className="flex h-full justify-end items-end gap-40">
            <div className="shape-8-setengah blur-[110px]"></div>
            <div className="shape-7-setengah blur-[110px]"></div>
            <div className="shape-6-setengah blur-[110px] ml-[-10rem]"></div>
          </div>
        </div>
        <div className="relative mt-[20rem] mt-[-7rem] lg:mt-0">
          <div className="flex justify-center">
            <hr className="bg-[#D4ADFC] w-11/12 h-[5px]"></hr>
          </div>
          <div className="flex justify-center flex-wrap gap-12 text-white py-5">
            <h4>My social Media</h4>
            <div className="flex flex-wrap gap-5">
              <Link
                to="https://wa.me/6285934816493"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="logos:whatsapp-icon" width={20} />
              </Link>
              <Link
                to="https://www.instagram.com/luthfiyyahnh_/?igshid=OGQ5ZDc2ODk2ZA%3D%3D"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="skill-icons:instagram" width={20} />
              </Link>
              <Link
                to="https://www.linkedin.com/in/luthfiyyah-n-h"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="devicon:linkedin" width={20} />
              </Link>
              <Link
                to="https://github.com/luthfiyyahnh"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon icon="fontisto:github" width={20} color="white" />
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default App;
