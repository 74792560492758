import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import Cookies from "js-cookie";

export default function Navbar() {
  const [counter, setCounter] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const [isMobile, setIsMobile] = useState(false);
  const [dropdownMenu, setDropdownMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleResize = () => {
    setIsMobile(window.innerWidth < 890);
  };

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleDropdown = () => {
    setDropdownMenu(!dropdownMenu);
  };

  useEffect(() => {
    const userId = Cookies.get("userId");

    if (!isLoading && !userId) {
      fetch("https://api.dreamit.my.id/api/visit/update/2", {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => {
          setCounter(data.pengunjung);
          Cookies.set("userId", "1", { expires: 1 });
        })
        .catch((error) => {
          console.error("Failed to update visitor counter:", error);
        });
    } else {
      fetch("https://api.dreamit.my.id/api/visit/view/2", {
        method: "GET",
      }).then((response) =>
        response.json().then((data) => {
          setCounter(data.pengunjung);
        })
      );
    }
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <div
        className={`text-white grid grid-cols-2 px-10 py-3 place-content-between w-screen transition-all ${
          scrollPosition > 5 ? "bg-[#0C102D] bg-opacity-90" : "bg-transparent"
        }`}
      >
        <div className="flex flex-row-reverse gap-3 justify-between md:justify-evenly items-center">
          <div className={isMobile ? "hidden" : "flex justify-around gap-5"}>
            <a className="hover:text-[#9855D0]" href="#about">
              About
            </a>
            <a className="hover:text-[#9855D0]" href="#experience">
              Experience
            </a>
            <a className="hover:text-[#9855D0]" href="#project">
              Project
            </a>
            <a className="hover:text-[#9855D0]" href="#skills">
              Skills
            </a>
            <a className="hover:text-[#9855D0]" href="#contact">
              Contact
            </a>
          </div>
          <div className="font-bold text-2xl">
            {isMobile ? (
              <p className="md:text-xl text-[14px]">Luthfiyyah N.</p>
            ) : (
              <p>Luthfiyyah Nurhasannah</p>
            )}
          </div>
          {isMobile && (
            <div className="flex justify-end">
              <button onClick={toggleDropdown}>
                <Icon icon="iconamoon:menu-burger-horizontal-bold" width="30" />
              </button>
            </div>
          )}
        </div>
        <div className="flex items-center justify-end md:pr-20">
          <Icon icon="line-md:person-search" width={25} />
          {counter}
        </div>
      </div>
      <div
        className={`${
          dropdownMenu
            ? "flex flex-col gap-3 text-center bg-[#0C102D] bg-opacity-90 w-full opacity-100 duration-1000 py-3 text-white "
            : "opacity-0 hidden"
        }`}
      >
        <a className="hover:text-[#9855D0]" href="#about">
          About
        </a>
        <a className="hover:text-[#9855D0]" href="#experience">
          Experience
        </a>
        <a className="hover:text-[#9855D0]" href="#project">
          Project
        </a>
        <a className="hover:text-[#9855D0]" href="#skills">
          Skills
        </a>
        <a className="hover:text-[#9855D0]" href="#contact">
          Contact
        </a>
      </div>
    </>
  );
}
